* {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  color: white;
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: cadetblue; }

.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column; }
  .wrapper .header {
    height: 10vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem; }
  .wrapper .content {
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .wrapper .navbar {
    background-color: #ad4848;
    height: 10vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly; }
    .wrapper .navbar div {
      border-radius: 50px;
      height: 80%;
      padding: 0 25px;
      max-width: 30%;
      display: flex;
      justify-content: center;
      align-items: center; }

.pokeball {
  width: 150px; }
