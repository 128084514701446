.wrapper {
  // border: green 2px solid;
  // background-color: rgb(173, 72, 72);
  // border-radius: 50px;
  height: 60vh;
  width: 85vw;
  max-width: 700px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: center;

  button {
    width: 75px;
    height: 50px;
    min-width: 100px;
    background-color: rgb(139, 56, 56);
    border: none;
    border-radius: 50px;

    &:hover {
      background-color: rgb(119, 47, 47);
    }
  }

  header {
    // border: blue 2px solid;
    background-color: rgb(173, 72, 72);
    border-radius: 50px;
    padding: 2%;
    height: 27.5%;
    width: 100%;
    display: flex;
    flex-direction: column;
    h2 {
      // border: olive 2px solid;
      height: 50%;
    }
    .score {
      // border: orange solid 2px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      height: 50%;
    }
  }

  .gameCanvas {
    // border: yellow 2px solid;
    background-color: rgb(173, 72, 72);
    border-radius: 50px;
    height: 67.5%;
    width: 100%;

    .preGameCanvas {
      // border: 2px blue solid;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      height: 100%;

      .postGameCanvas {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;

        button {
          width: 100%;
          height: 50%;
        }
      }

      div {
        width: 50%;
        height: 25%;

        button {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
