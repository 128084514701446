.main {
  // border: greenyellow 2px solid;
  // background-color: cadetblue;
  width: 100%;
  height: 100%;
  text-align: center;
  .headerContainer {
    display: flex;
    justify-content: center;
    header {
      // border: burlywood 2px solid;
      background-color: rgb(173, 72, 72);
      border-radius: 50px;
      width: 95%;
      display: flex;
      justify-content: space-evenly;
      button {
        height: 100%;
        min-width: 70px;
        background-color: rgb(139, 56, 56);
        border: none;
        border-radius: 50px;

        &:hover {
          background-color: rgb(119, 47, 47);
        }
      }

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
      }
    }
  }
  .info {
    // border: green 2px solid;
    display: flex;
    flex-direction: column;
    padding: 2.5%;
    width: 100%;
    height: 100%;

    & > * {
      margin: 10px auto;
    }
    .pokeData {
      // border: red solid 2px;
      background-color: rgb(173, 72, 72);
      border-radius: 50px;
      width: 100%;
      height: 275px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      & > * {
        // border: blue 2px solid;
        width: 95%;
        margin: 10px auto;
        display: flex;
        justify-content: space-evenly;

        div {
          width: 50%;
        }
      }
    }

    .pokeDescription {
      // border: cadetblue solid 2px;
      background-color: rgb(173, 72, 72);
      border-radius: 50px;
      text-align: center;
      height: 125px;
      max-width: 500px;
      overflow: scroll;

      &::-webkit-scrollbar {
        display: none;
        // width: 0;
      }

      & > * {
        margin: 2.5% 5%;
      }
    }
  }
}
