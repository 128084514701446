.wrapper {
  //   border: blue 2px solid;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  h3 {
    // border: blueviolet 2px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 10%;
  }

  .answerImg {
    // border: black 2px solid;
    height: 50%;
    width: 100%;
    img {
      height: 100%;
    }
  }

  .answers {
    // border: yellow 2px solid;
    height: 35%;
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    button {
      height: 45%;
      width: 40%;
      min-width: 100px;

      background-color: rgb(139, 56, 56);
      border: none;
      border-radius: 50px;

      &:hover {
        background-color: rgb(119, 47, 47);
      }
    }
  }
}
