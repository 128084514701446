body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

* {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  color: white;
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: cadetblue; }

.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column; }
  .wrapper .header {
    height: 10vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem; }
  .wrapper .content {
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .wrapper .navbar {
    background-color: #ad4848;
    height: 10vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly; }
    .wrapper .navbar div {
      border-radius: 50px;
      height: 80%;
      padding: 0 25px;
      max-width: 30%;
      display: flex;
      justify-content: center;
      align-items: center; }

.pokeball {
  width: 150px; }

.HomePage_wrapper__2vYe5 {
  width: 100vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center; }
  .HomePage_wrapper__2vYe5 > * {
    margin: 25px 0; }


.SearchBar_searchBar__1PQZt {
  color: black;
  width: calc(75% - 25px);
  margin-top: 15px;
  font-size: 1em; }

.PokedexPage_wrapper__39fzO {
  background-color: #ad4848;
  border-radius: 50px;
  width: 85vw;
  height: 75vh;
  max-width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center; }
  .PokedexPage_wrapper__39fzO header {
    background-color: #56797a;
    border-radius: 50px 50px 0 0;
    width: 90%;
    padding: 10px 0; }
  .PokedexPage_wrapper__39fzO .PokedexPage_pokedexCards__3vSA2 {
    border: #56797a 5px solid;
    border-radius: 0 0 50px 50px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 90%;
    height: 75%;
    overflow: scroll; }
    .PokedexPage_wrapper__39fzO .PokedexPage_pokedexCards__3vSA2 > * {
      text-decoration: none;
      width: 50%;
      max-width: 150px;
      margin-bottom: 15px; }
    .PokedexPage_wrapper__39fzO .PokedexPage_pokedexCards__3vSA2::-webkit-scrollbar {
      width: 0; }

.HighScorePage_wrapper__UKxHN {
  background-color: #ad4848;
  border-radius: 50px;
  width: 85vw;
  height: 75vh;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  overflow: scroll; }
  .HighScorePage_wrapper__UKxHN h2 {
    height: 10%; }
  .HighScorePage_wrapper__UKxHN > div {
    height: 80%; }
    .HighScorePage_wrapper__UKxHN > div p:first-child {
      margin-right: auto; }
  .HighScorePage_wrapper__UKxHN .HighScorePage_highScore__1k5ig {
    max-width: 600px;
    margin: 5px;
    width: 40vw;
    display: flex;
    justify-content: space-evenly; }
  .HighScorePage_wrapper__UKxHN::-webkit-scrollbar {
    width: 0; }

.GamePage_wrapper__3ZcKs {
  height: 60vh;
  width: 85vw;
  max-width: 700px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: center; }
  .GamePage_wrapper__3ZcKs button {
    width: 75px;
    height: 50px;
    min-width: 100px;
    background-color: #8b3838;
    border: none;
    border-radius: 50px; }
    .GamePage_wrapper__3ZcKs button:hover {
      background-color: #772f2f; }
  .GamePage_wrapper__3ZcKs header {
    background-color: #ad4848;
    border-radius: 50px;
    padding: 2%;
    height: 27.5%;
    width: 100%;
    display: flex;
    flex-direction: column; }
    .GamePage_wrapper__3ZcKs header h2 {
      height: 50%; }
    .GamePage_wrapper__3ZcKs header .GamePage_score__ltQwc {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      height: 50%; }
  .GamePage_wrapper__3ZcKs .GamePage_gameCanvas__1gs_c {
    background-color: #ad4848;
    border-radius: 50px;
    height: 67.5%;
    width: 100%; }
    .GamePage_wrapper__3ZcKs .GamePage_gameCanvas__1gs_c .GamePage_preGameCanvas__3xtKY {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      height: 100%; }
      .GamePage_wrapper__3ZcKs .GamePage_gameCanvas__1gs_c .GamePage_preGameCanvas__3xtKY .GamePage_postGameCanvas__kQKni {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 100%; }
        .GamePage_wrapper__3ZcKs .GamePage_gameCanvas__1gs_c .GamePage_preGameCanvas__3xtKY .GamePage_postGameCanvas__kQKni button {
          width: 100%;
          height: 50%; }
      .GamePage_wrapper__3ZcKs .GamePage_gameCanvas__1gs_c .GamePage_preGameCanvas__3xtKY div {
        width: 50%;
        height: 25%; }
        .GamePage_wrapper__3ZcKs .GamePage_gameCanvas__1gs_c .GamePage_preGameCanvas__3xtKY div button {
          width: 100%;
          height: 100%; }

.GameTurn_wrapper__6D-pZ {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; }
  .GameTurn_wrapper__6D-pZ h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 10%; }
  .GameTurn_wrapper__6D-pZ .GameTurn_answerImg__35Nji {
    height: 50%;
    width: 100%; }
    .GameTurn_wrapper__6D-pZ .GameTurn_answerImg__35Nji img {
      height: 100%; }
  .GameTurn_wrapper__6D-pZ .GameTurn_answers__34uy0 {
    height: 35%;
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; }
    .GameTurn_wrapper__6D-pZ .GameTurn_answers__34uy0 button {
      height: 45%;
      width: 40%;
      min-width: 100px;
      background-color: #8b3838;
      border: none;
      border-radius: 50px; }
      .GameTurn_wrapper__6D-pZ .GameTurn_answers__34uy0 button:hover {
        background-color: #772f2f; }

.PokedexEntry_main__2VkL3 {
  width: 100%;
  height: 100%;
  text-align: center; }
  .PokedexEntry_main__2VkL3 .PokedexEntry_headerContainer__2XSMb {
    display: flex;
    justify-content: center; }
    .PokedexEntry_main__2VkL3 .PokedexEntry_headerContainer__2XSMb header {
      background-color: #ad4848;
      border-radius: 50px;
      width: 95%;
      display: flex;
      justify-content: space-evenly; }
      .PokedexEntry_main__2VkL3 .PokedexEntry_headerContainer__2XSMb header button {
        height: 100%;
        min-width: 70px;
        background-color: #8b3838;
        border: none;
        border-radius: 50px; }
        .PokedexEntry_main__2VkL3 .PokedexEntry_headerContainer__2XSMb header button:hover {
          background-color: #772f2f; }
      .PokedexEntry_main__2VkL3 .PokedexEntry_headerContainer__2XSMb header div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%; }
  .PokedexEntry_main__2VkL3 .PokedexEntry_info__1TuCI {
    display: flex;
    flex-direction: column;
    padding: 2.5%;
    width: 100%;
    height: 100%; }
    .PokedexEntry_main__2VkL3 .PokedexEntry_info__1TuCI > * {
      margin: 10px auto; }
    .PokedexEntry_main__2VkL3 .PokedexEntry_info__1TuCI .PokedexEntry_pokeData__1i7dG {
      background-color: #ad4848;
      border-radius: 50px;
      width: 100%;
      height: 275px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column; }
      .PokedexEntry_main__2VkL3 .PokedexEntry_info__1TuCI .PokedexEntry_pokeData__1i7dG > * {
        width: 95%;
        margin: 10px auto;
        display: flex;
        justify-content: space-evenly; }
        .PokedexEntry_main__2VkL3 .PokedexEntry_info__1TuCI .PokedexEntry_pokeData__1i7dG > * div {
          width: 50%; }
    .PokedexEntry_main__2VkL3 .PokedexEntry_info__1TuCI .PokedexEntry_pokeDescription__1TFvN {
      background-color: #ad4848;
      border-radius: 50px;
      text-align: center;
      height: 125px;
      max-width: 500px;
      overflow: scroll; }
      .PokedexEntry_main__2VkL3 .PokedexEntry_info__1TuCI .PokedexEntry_pokeDescription__1TFvN::-webkit-scrollbar {
        display: none; }
      .PokedexEntry_main__2VkL3 .PokedexEntry_info__1TuCI .PokedexEntry_pokeDescription__1TFvN > * {
        margin: 2.5% 5%; }

