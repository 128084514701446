.wrapper {
  // border: green 2px solid;
  background-color: rgb(173, 72, 72);
  border-radius: 50px;
  width: 85vw;
  height: 75vh;
  max-width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  header {
    // border: 2px solid orangered;
    background-color: rgb(86, 121, 122);
    border-radius: 50px 50px 0 0;
    width: 90%;
    padding: 10px 0;
    // h2 {
    //   color: grey;
    // }
  }

  .pokedexCards {
    border: rgb(86, 121, 122) 5px solid;
    border-radius: 0 0 50px 50px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 90%;
    height: 75%;
    overflow: scroll;

    & > * {
      // border: red solid 2px;
      text-decoration: none;
      width: 50%;
      max-width: 150px;
      margin-bottom: 15px;
    }

    &::-webkit-scrollbar {
      width: 0;
    }
  }
}
