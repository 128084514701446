* {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: white;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: cadetblue;
}

.wrapper {
  // border: red solid 2px;
  width: 100vw;
  // max-width: 750px;
  height: 100vh;
  // max-height: 700px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  // text-align: center;

  .header {
    // border: blue solid 2px;
    height: 10vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
  }

  .content {
    // border: red 2px solid;
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar {
    // border: blue solid 2px;
    background-color: rgb(173, 72, 72);
    height: 10vh;
    width: 100%;
    // max-width: 750px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    div {
      // background-color: rgb(136, 58, 58);
      border-radius: 50px;
      height: 80%;
      padding: 0 25px;
      max-width: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.pokeball {
  width: 150px;
}
