.wrapper {
  background-color: rgb(173, 72, 72);
  border-radius: 50px;
  width: 85vw;
  height: 75vh;
  max-width: 700px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  overflow: scroll;

  h2 {
    height: 10%;
  }

  & > div {
    height: 80%;
    // border: white 2px solid;

    & p:first-child {
      margin-right: auto;
    }
  }

  .highScore {
    max-width: 600px;

    // text-align: left;
    // border: green 2px solid;
    margin: 5px;
    width: 40vw;
    display: flex;
    justify-content: space-evenly;
  }

  &::-webkit-scrollbar {
    width: 0;
  }
}
